import SourceFlowText from "@sourceflow-uk/sourceflowtext";
import useGlobal from "@/hooks/useGlobal";

export default function CustomSourceflowText({
  title,
  path,
  html_format = false,
  locale = "en",
  ...props
}) {
  const global = useGlobal();

  return (
    <SourceFlowText
      global={global}
      path={`${path}.${locale}`}
      type={html_format ? "html" : "text"}
      {...props}
    >
      {title}
    </SourceFlowText>
  );
}
